.custom-nav-row-item {
    margin: 0em 0.5em;
}

.custom-nav-column-item {
    margin: 0.4em 0em;
}

.navbar {
    font-size: "1.5em"
}