.wrapper {
    /**
    * User input values.
    */
    --grid-layout-gap: 2em;
    --grid-column-count: 2;
    --grid-item--min-width: 320px;

    /**
    * Calculated values.
    */
    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
    grid-gap: var(--grid-layout-gap);
    margin: 1.5em;
    font-family: "Verdana";
    align-items: "center";
}

.wrapper > img {
    max-height: 350px;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    margin: auto;
}

.section-header {
    margin-bottom: 0.5em;
}

.text-area {
    margin: auto 2em;
}

.text-area > .fixture-nav {
    margin-top: 1em;
}